

import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import {KisiSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KisiSorgulama";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
  components: {FormWrapper, TcKimlikNumarasi}
})
export default class KisiSorgulaForm extends Vue {
  @Ref('form') form!: FormWrapper;

  islemDegerleri: KisiSorgulamaTalep = {
    tcKimlikNo: ""
  }

  onSubmit() {
    if (this.form.validateForm())
    this.$emit('onSubmit', this.islemDegerleri);
  }
}
