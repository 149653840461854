
import {Component, Ref, Vue} from "vue-property-decorator";
import DetsisNumarasi from "@/components/inputs/DetsisNumarasi.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import MersisNumarasi from "@/components/inputs/MersisNumarasi.vue";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
  components: {FormWrapper, MersisNumarasi, VergiNumarasi, DetsisNumarasi}
})

export default class KurumSorgulaForm extends Vue {
  @Ref('form') readonly form!: FormWrapper;

  islemDegerleri = {
    mersisNo: "",
    vergiNo: "",
    detsisNo: "",
    kurumAdi: ""
  }

  onSubmit() {
    if (this.form.validateForm())
      this.$emit('onSubmit', this.islemDegerleri);
  }
}
