
import {Component, Prop, Vue} from "vue-property-decorator";
import {KurumSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/KurumSorgulama";

@Component({
  components: {}
})
export default class KurumSorgulaInfo extends Vue {
  @Prop({required: true, default: {}}) cevap!: KurumSorgulamaCevap;

  headers = [
    {text: "Kurum Adı", value: "kurumAdi"},
    {text: "Mersis No", value: "mersisNo"},
    {text: "Ssk İşyeri Sicil No", value: "sskIsyeriSicilNo"},
    {text: "Vergi No", value: "vergiNo"},
    {text: "Harç Durumu", value: "harcDurumu"},
    {text: "Kamu/Özel", value: "kamuOzel"},
    {text: "Vergi Geliş Yeri", value: "veriGelisYeri"},
    {text: "Tacir Mi", value: "tacirMi"},
    {text: "Mersis İl Kodu", value: "mersisIlKodu"},
    {text: "Mersis İlçe Kodu", value: "mersisIlceKodu"},
  ]
}
