

import {Component, Vue, Watch} from "vue-property-decorator"
import KisiSorgulaForm from "@/components/forms/KisiSorgulaForm.vue";
import KisiSorgulaInfo from "@/components/infos/KisiSorgulaInfo.vue";
import {KisiSorgulamaCevap, KisiSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KisiSorgulama";
import KurumSorgulaForm from "@/components/forms/KurumSorgulaForm.vue";
import KurumSorgulaInfo from "@/components/infos/KurumSorgulaInfo.vue";
import {KurumSorgulamaCevap, KurumSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KurumSorgulama";
import app from '@/main';
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {
    UyapBagliDegilView,
    KurumSorgulaInfo,
    KurumSorgulaForm,
    KisiSorgulaForm,
    KisiSorgulaInfo,
  }
})
export default class KisiKurumSorgulaView extends Vue {
  cevapKisi: KisiSorgulamaCevap | {} = {};
  cevapKurum: KurumSorgulamaCevap[] | {} = {};
  show = false;

  async onKisiSorgulaSubmit(talep: KisiSorgulamaTalep) {
    try {
      this.show = true;
      this.cevapKisi = await this.$uyap.KisiSorgula().run(talep);
    } catch (e) {
      app.$toast.error(e.message)
    } finally {
      this.show = false;
    }
  }

  async onKurumSorgulaSubmit(talep: KurumSorgulamaTalep) {
    try {
      this.show = true;
      this.cevapKurum = await this.$uyap.KurumSorgulama().run(talep);
    } catch (e) {
      app.$toast.error(e.message)
    } finally {
      this.show = false;
    }
  }
}
